import type { TilesContent } from '../app/context/ContentContext/contentTypes';
import { getRelatedElement } from './getRelatedElement';
import type { TileElement } from './types';

const buildImpressionEvent = (totalTiles: number, relatedElements: TileElement[]) => ({
  eventInfo: {
    eventAction: 'impression',
    eventName: 'content tiles - impression',
  },
  attributes: {
    componentName: 'content-tiles',
    label: '',
    targetURL: '',
    clickID: '',
    elementName: '',
    value: '',
    pos: totalTiles.toString(),
    relatedElements,
  },
});

export const getImpresionEvent = (content: TilesContent) => {
  const totalTiles = content.tiles.length;
  const relatedElements = content.tiles.map((tile, index) => {
    const position = index + 1;
    return getRelatedElement(tile, position);
  });

  return buildImpressionEvent(totalTiles, relatedElements);
};
