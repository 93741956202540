import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import type { Tile } from '../app/context/ContentContext/contentTypes';
import { getRelatedElement } from './getRelatedElement';

const fileExtRegex = /^.*\.(jpg|JPG|gif|GIF|doc|DOC|pdf|PDF|png|PNG)/g;

export const getClickEventAction = (trackingService: TrackingServiceV2, sanitizedUrl: string) => {
  const evaluatedLink = trackingService.evaluateLinkType(sanitizedUrl);
  if (evaluatedLink) {
    return evaluatedLink;
  }

  if (sanitizedUrl?.includes('download') || sanitizedUrl?.match(fileExtRegex)) {
    return 'download';
  }

  if (sanitizedUrl?.startsWith('#')) {
    return 'internal_link';
  }

  return 'content';
};

export const getSanitizedUrl = (trackingService: TrackingServiceV2, tile: Tile) => {
  return trackingService.sanitizeUrl(tile.CTA?.href || '');
};

export const getClickEvent = (
  eventAction: string,
  sanitizedUrl: string,
  tile: Tile,
  index: number,
) => {
  const position = index + 1;
  return {
    eventInfo: {
      eventAction,
      eventName: 'content tiles - click on content tile',
    },
    attributes: {
      componentName: 'content-tiles',
      label: tile.CTA?.text || tile.heading?.text || '',
      targetURL: sanitizedUrl,
      clickID: '',
      elementName: 'text link',
      value: '',
      pos: position.toString(),
      relatedElements: [getRelatedElement(tile, position)],
    },
  };
};
