export const CT_LAYOUT = {
  IRREGULAR_2X1_SPLIT_COLUMN_RIGHT: 'Irregular - 2x1 split column right',
  IRREGULAR_2X1_SPLIT_COLUMN_LEFT: 'Irregular - 2x1 split column left',
  BASIC_2X1: 'Basic - 2x1',
  BASIC_3X1: 'Basic - 3x1',
  BASIC_2X2: 'Basic - 2x2',
  IRREGULAR_2X1_WIDE: 'Irregular - 2x1 wide',
  IRREGULAR_1X2_SPLIT_ROW: 'Irregular - 1x2 split row',
};

export const CT_SIZE = {
  TALL: 'tall',
  STANDARD: 'standard',
  SHORT: 'short',
};
