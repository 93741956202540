/* eslint-disable react/function-component-definition */
/**
 * This is the starting point of your application.
 * oneAudi OS and Feature Hub Apps will use this file to bootstrap the app.
 */
import React, { useContext, useEffect } from 'react';
import { getReadyAdditionalObjects } from '../tracking/getReadyAdditionalObjects';
import { readyEvent } from '../tracking/readyEvent';
import { ContentTiles } from './components/ContentTiles';
import { ContentContext } from './context/ContentContext';
import { useTrackingService } from './hooks/useTrackingService';

const FeatureApp: React.FC = () => {
  const context = useContext(ContentContext);

  const { trackingService } = useTrackingService();

  useEffect(() => {
    if (trackingService) {
      trackingService.track({
        event: readyEvent,
        componentUpdate: getReadyAdditionalObjects(),
      });
    }
  }, []);

  return <ContentTiles {...context.content} />;
};

export default FeatureApp;
