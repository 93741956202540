import { getElementCoords } from './getElementCoordinates';

const getSectionId = (id: string) => (id.startsWith('#') ? id.substring(1) : id);

export const scrollToElement = (id: string) => {
  if (typeof window !== 'undefined') {
    const element = document.getElementById(getSectionId(id));
    if (element) {
      window.scrollTo({
        top: getElementCoords(element).top,
        behavior: 'smooth',
      });
    }
  }
};
