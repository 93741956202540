/* eslint-disable @typescript-eslint/no-explicit-any */
import { string } from 'prop-types';
import React from 'react';

import { scrollToElement } from './scrollToElement';

type ActionProps = {
  href: string;
  onClick?: (e: React.MouseEvent) => void;
};

export const withScrollToElement = (Wrapped: React.FC<any>) => {
  function Container({ href, onClick, ...rest }: ActionProps & { [key: string]: any }) {
    const isElementId = typeof href === 'string' ? href.startsWith('#') : '';

    const actionProps: ActionProps = {
      href,
      onClick,
    };

    if (isElementId) {
      const handleScroll = (id: string, e: React.MouseEvent) => {
        e.preventDefault();
        scrollToElement(id);
      };

      actionProps.onClick = (e) => {
        if (onClick) {
          onClick(e);
        }
        handleScroll(href, e);
      };
    }

    return <Wrapped {...actionProps} {...rest} />;
  }

  Container.displayName = 'WithScrollToElement';

  Container.propTypes = {
    href: string.isRequired,
  };

  return Container;
};
