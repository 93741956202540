const SplitColumnSizes = {
  sizeOne: {
    tall: 225 / 218,
    standard: 75 / 109,
  },
  sizeTwo: {
    tall: 1 / 2,
    standard: 5 / 13,
  },
};

export const TileSizes = {
  '375 breakpoint': {
    tall: 3 / 2,
    standard: 1 / 1,
    short: 2 / 3,
  },
  '768 breakpoint': {
    tall: 3 / 4,
    standard: 1 / 2,
    short: 3 / 10,
  },
  'Basic - 2x1': {
    tall: 225 / 218,
    standard: 75 / 109,
    short: 53 / 109,
  },
  'Basic - 3x1': {
    tall: 25 / 16,
    standard: 25 / 24,
    short: 25 / 34,
  },
  'Basic - 2x2': {
    tall: 1 / 2,
    standard: 5 / 13,
  },
  'Irregular - 2x1 wide': {
    tall: 225 / 292,
    standard: 75 / 146,
    short: 53 / 146,
  },
  'Irregular - 2x1 split column right': SplitColumnSizes,
  'Irregular - 2x1 split column left': SplitColumnSizes,
  'Irregular - 1x2 split row': {
    sizeOne: {
      tall: 10 / 33,
    },
    sizeTwo: {
      tall: 53 / 109,
    },
  },
};
