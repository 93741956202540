export const readyEvent = {
  eventInfo: { eventAction: 'feature_app_ready', eventName: 'content tiles - feature app ready' },
  attributes: {
    componentName: 'content-tiles',
    label: '',
    targetURL: '',
    clickID: '',
    elementName: '',
    value: '',
    pos: '',
  },
};
