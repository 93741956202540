export function getElementCoords(elem: HTMLElement) {
  const { top: elTop, left: elLeft } = elem.getBoundingClientRect();
  const { body, documentElement: docEl } = document;

  const scrollTop =
    (typeof window !== 'undefined' && window.scrollY) || docEl.scrollTop || body.scrollTop;
  const scrollLeft =
    (typeof window !== 'undefined' && window.scrollX) || docEl.scrollLeft || body.scrollLeft;

  const clientTop = docEl.clientTop || body.clientTop || 0;
  const clientLeft = docEl.clientLeft || body.clientLeft || 0;

  const top = elTop + scrollTop - clientTop;
  const left = elLeft + scrollLeft - clientLeft;

  return { top: Math.round(top), left: Math.round(left) };
}
