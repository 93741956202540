import React from 'react';
import styled from 'styled-components';

// TODO: remove this when WEBSUPPORT-17880 is completed
// TODO: https://collaboration.msi.audi.com/jira/browse/WEBSUPPORT-17880

const FocusReset = styled.div`
  a:focus,
  embed:focus,
  object:focus {
    outline: revert;
  }
`;

export const withFocusReset = (Component) => {
  function WithFocusReset({ ...props }) {
    return (
      <FocusReset>
        <Component {...props} />
      </FocusReset>
    );
  }

  WithFocusReset.displayName = `WithFocusReset(${
    /* istanbul ignore next */
    Component.displayName || Component.name || 'Component'
  })`;

  return WithFocusReset;
};
